object{
    width: 95%;
    height: 100vh;
    margin: 10px 2.5%;
}
.resume1,.resume2{
    width: 90%;
    margin: 0 5%;

}
.resume1{
    animation: .3s ease-in-out 0s slideFromTop;
}
.resume2{
    animation: .3s ease-in-out 0s slideFromBottom;
    margin-top: -3px;
}

@keyframes slideFromTop {
    0%{
        transform: translateY(-100px);
    }
    100%{
        transform: translateY(0px);
    }
}
@keyframes slideFromBottom {
    0%{
        transform: translateY(100px);
    }
    100%{
        transform: translateY(0px);
    }
}