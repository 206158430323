.navbar{
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.left-side-of-navbar{
    display: flex;
    align-items:center;
    margin-left: 20px;
}
.initial-box{
    display: flex;
    flex-direction: column;
    padding: 10px;
    border: 2px solid rgb(10, 156, 156);
    border-radius: 10px;
    min-width: max-content;
}
.initial-box i{
    color: rgb(10, 156, 156);
}
.left-side-of-navbar p{
    margin-left: 10px;
    color: rgb(235, 227, 227);
}
.logo{
    width: 75px;
    height: auto;
    margin-left: 10px;
}
.name{
    display: flex;
    align-items: center;
}
.right-side-of-navbar{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 20px;
}

#home,#about,#resume,#portfolio,#contact{
    margin: 8px;
    text-decoration: none;
    transition: .5s ease-in-out;
    min-width: max-content;
    color: rgb(235, 227, 227);
}
#home:hover,#about:hover,#resume:hover,#portfolio:hover,#contact:hover{
     text-decoration: underline;
    text-decoration-color: rgb(10, 156, 156);
    font-size: large;
    cursor: pointer;
}

@media (width < 499px){
    .fa-bars,.fa-x{
        color: rgb(235, 227, 227);
        margin-right: 20px;
        font-size: x-large;
    }
    .sidebar{
        width: fit-content;
        height: auto;
        padding: 10px;

    }
}