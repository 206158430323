.contact-body{
    background-color: rgb(10, 156, 156);
    width: max-content;
    border-radius: 10px;
    border: none;
    position: fixed;
    top: 80px;
    right: 20px;
    padding: 10px;
    animation: .3s ease-out 0s 1 slideInFromRight;
}
#contact2{
    font-size: larger;
    font-weight: bold;
    margin-top: 0px;
    color: rgb(235, 227, 227);
}
.indent{
    margin-left: 10px;
    color: black;
}
.fa-linkedin{
    transition: .3s ease-in-out;
}
.fa-linkedin:hover{
    color: rgb(235, 227, 227);
}
@keyframes slideInFromRight {
    0% {
        transform: translateX(1000px);
        opacity: .10;
    }
    20%{
        opacity: .30;
    }
    40%{
        opacity: .50;
    }
    60%{
        opacity: .70;
    }
    80%{
        opacity: .90;
    }
    100% {
        transform: translateX(0px);
        opacity: 1;
    }
}