.about-body{
    padding: 20px;
}
.descrip-pp-container{
    display: flex;
    justify-content: space-around;
}
.description h2,
.description h4{
    color: rgb(235, 227, 227);
}
.description h4{
    margin: 5px 0px;
}
.description li{
    color: rgb(10, 156, 156);
    margin-bottom: 10px;
}
.profile-pic-about{
    width: 225px;
    height: auto;
    border-radius: 5px;
    animation: .7s ease-out 0s 1 slideInFromRightToLeft;
}

@media (width < 499px){
    .about-body{
        padding: 5px;
    }
    .descrip-pp-container{
        flex-direction: column-reverse;
        align-items: center;
    }
    .description{
      width: 80%;
    }
}