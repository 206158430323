.portfolio-body{
    padding: 20px;
}
.projects-list{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
}
h1,h2,h3{
    color: rgb(235, 227, 227);
    animation: .7s ease-in-out 0s 1 slideInFromLeftToRight;
}
.left-side-of-project{
    width: 40%;
}
li{
    color: rgb(10, 156, 156);
    animation: .7s ease-in-out 0s 1 slideInFromBottomToTop;
    
}
.Link-to-project-btn{
    border-radius: 10px;
    padding: 10px 20px;
    background-color: rgb(235, 227, 227);
    color: rgb(10, 156, 156);
    border: none;
    transition: .5s ease-in-out;
}
.Link-to-project-btn:hover{
    color: rgb(235, 227, 227);
    background-color: rgb(10, 156, 156);
    cursor: pointer;
    animation: .5s ease 0s 1 shakeElement;
}
.right-side-of-project{
    width: 40%;
}
.lightsFinanceLandingPage{
    width: 100%;
    height: auto;
    animation: .7s ease-in-out 0s 1 slideInFromRight;
}
.lightsFinanceLandingPage:hover{
    cursor: pointer;
}


@keyframes shakeElement{
    0%, 20%, 40%, 60%, 80%{
        transform: translateX(-5px);
    }
    10%,30%,50%,70%,90%{
        transform: translateX(5px);
    }
    100%{
        transform: translateX(0px);
    }
}

@keyframes slideInFromRight{
    0% {
        transform: translateX(1000px);
        opacity: .10;
    }
    20%{
        opacity: .30;
    }
    40%{
        opacity: .50;
    }
    60%{
        opacity: .70;
    }
    80%{
        opacity: .90;
    }
    100% {
        transform: translateX(0px);
        opacity: 1;
    }
}

@keyframes slideInFromLeftToRight {
    0% {
        transform: translateX(-1000px);
        opacity: .10;
    }
    20%{
        opacity: .30;
    }
    40%{
        opacity: .50;
    }
    60%{
        opacity: .70;
    }
    80%{
        opacity: .90;
    }
    100% {
        transform: translateX(0px);
        opacity: 1;
    }
}

@keyframes slideInFromBottomToTop {
    0% {
        transform: translateY(1000px);
        opacity: .10;
    }
    20%{
        opacity: .30;
    }
    40%{
        opacity: .50;
    }
    60%{
        opacity: .70;
    }
    80%{
        opacity: .90;
    }
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

@media (width < 499px){
    .portfolio-body{
        padding: 5px;
    }
    .projects-list{
        display: flex;
        flex-direction: column-reverse;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;
    }
    .left-side-of-project{
        width: 80%;
    }
    .right-side-of-project{
        width: 80%;
    }
}